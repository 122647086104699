
import { defineComponent, onMounted, reactive, toRefs, ref, watch } from "vue";
import moment from 'moment';
import { getOrcamentos } from "@/services/OnePageReportService";
import { getFaturamentoOrcamentos } from "@/services/OnePageReportService";
import { useRouter } from "vue-router";
import  OficinaDigitalHistoricoDeOrcamentos from "../../views/oficina-digital/historico/OficinaDigitalHistoricoOrcamentos.vue";
import { ContentLoader } from 'vue-content-loader';
import store from "@/store/index";


export default defineComponent({
    name: "OficinaDigitalCard",
    components: {
        OficinaDigitalHistoricoDeOrcamentos,
        ContentLoader
    },
    props: {
        filters: Object,
        title: String,
    },
    setup(props) {
        const title = ref("");
        const router = useRouter();

        const data = reactive({
            orcamento: {
                totalOrcamento: 0,
                tempoRealOrcamento: 0,
                faturamentoTotalOrcamento: 0,
                faturamentoPecasOrcamento: 0,
                faturamentoMOOrcamento: 0,
            },
            seen: true,
            seen2: true,

        });

        let totalOrcamento = ref(0);
        let tempoRealOrcamento = ref(0);
        let faturamentoTotalOrcamento = ref(0);
        let faturamentoPecasOrcamento = ref(0);
        let faturamentoMOOrcamento = ref(0);
        let totalOrcamentoFormat = ref("");
        let tempoRealOrcamentoFormat = ref("");
        let faturamentoTotalOrcamentoFormat = ref("");
        let faturamentoPecasOrcamentoFormat = ref("");
        let faturamentoMOOrcamentoFormat = ref("");
        
        let animate = ref(true);
        
        onMounted(() =>{
        })

        function trataData(data){
            let filters = data;
            data = data.split("/");
            const dataInicial = moment(data[0]);
            const dataFinal = moment(data[1]);
            const mesmoAno = dataFinal.year() === dataInicial.year();
            const mesmoMes = dataFinal.month() === dataInicial.month();
            if (mesmoAno && mesmoMes) {
                title.value = `Período selecionado ${dataInicial.locale("pt-br").format('MMMM - YYYY').replaceAll("-", "de")}`;
                return filters;
            }else{
                title.value = ` Entre ${dataInicial.locale("pt-br").format('MMMM - YYYY').replaceAll("-", "de")} até ${dataFinal.locale("pt-br").format('MMMM - YYYY').replaceAll("-", "de")}`;
                return filters;
            }
        }

        function retornaFiltroData(dataInicial, dataFinal){
            dataInicial = moment(dataInicial).format();
            dataFinal = moment(dataFinal).format();
            dataInicial = dataInicial.substr(0, 10) + " 00:00:00";
            dataFinal = dataFinal.substr(0, 10) + " 23:59:59";

            const filters = `${dataInicial}/${dataFinal}`;

            return filters;
        };

        let link_v3 = "../v3"; 

         watch(() => props.filters, () => {
            // 2021-04-01/2021-05-31 É um exemplo com dados
             const filters = trataData(props.filters?.data); 

            let dominio_gestao = store.getters.layoutConfig("distribuidor.dominio_gestao");
            if(dominio_gestao == "localhost:8080"){
                link_v3 = "http://localhost/acampa-gestao-pra/v3/";
            }else{
                link_v3 =  "../v3/";
            }

            getOrcamentosList(props.filters?.codConcessionaria, props.filters?.data);
        });

        const codConcessionariaSelected:any = ref("");
        const optionsConcessionaria = ref([]);

    //função para chamar o componente da oficinadigitalgerenciar
         function Orcamentosrealizados() {
             router.push('/oficina-digital/gerenciar');
        };
    //fim função para chamar o componente da oficinadigitalgerenciar


        async function getOrcamentosList(codConcessionaria, filtersData){
            
		    animate.value = true;
            let diaAtuaInicial = moment().startOf("day").toISOString();
            let diaAtuaFinal = moment().endOf("day").toISOString();

            const dataTratada = retornaFiltroData(diaAtuaInicial, diaAtuaFinal);
            const orcamentoTotal = await getOrcamentos(codConcessionaria, filtersData);
            const orcamentoReal = await getOrcamentos(codConcessionaria, dataTratada);
            const orcamentoFaturamentos = await getFaturamentoOrcamentos(codConcessionaria, filtersData);
          
            totalOrcamento.value = 0;
            tempoRealOrcamento.value = 0;
            faturamentoTotalOrcamento.value = 0;
            faturamentoPecasOrcamento.value = 0;
            faturamentoMOOrcamento.value = 0;
            totalOrcamentoFormat.value = "0,00";
            tempoRealOrcamentoFormat.value = "0,00";
            faturamentoTotalOrcamentoFormat.value = "0,00";
            faturamentoPecasOrcamentoFormat.value = "0,00";
            faturamentoMOOrcamentoFormat.value = "0,00";

            totalOrcamento.value = orcamentoTotal.qtdOrcamentos;
            tempoRealOrcamento.value = orcamentoReal.qtdOrcamentos;
            faturamentoTotalOrcamento.value = orcamentoFaturamentos.faturamentoTotal;
            faturamentoPecasOrcamento.value = orcamentoFaturamentos.faturamentoPecas;
            faturamentoMOOrcamento.value = orcamentoFaturamentos.faturamentoMO;
            
            totalOrcamentoFormat.value = new Intl.NumberFormat('de-DE',{ maximumFractionDigits: 0, minimumFractionDigits:0 }).format(totalOrcamento.value);
            tempoRealOrcamentoFormat.value = new Intl.NumberFormat('de-DE',{ maximumFractionDigits: 0, minimumFractionDigits:0 }).format(tempoRealOrcamento.value);
            faturamentoTotalOrcamentoFormat.value = new Intl.NumberFormat('de-DE',{ maximumFractionDigits: 2, minimumFractionDigits:2 }).format(faturamentoTotalOrcamento.value);
            faturamentoPecasOrcamentoFormat.value = new Intl.NumberFormat('de-DE',{ maximumFractionDigits: 2, minimumFractionDigits:2 }).format(faturamentoPecasOrcamento.value);
            faturamentoMOOrcamentoFormat.value = new Intl.NumberFormat('de-DE',{ maximumFractionDigits: 2, minimumFractionDigits:2 }).format(faturamentoMOOrcamento.value);

		    animate.value = false;
        }

        return {
            link_v3,
            totalOrcamentoFormat,
            tempoRealOrcamentoFormat,
            faturamentoTotalOrcamentoFormat,
            faturamentoPecasOrcamentoFormat,
            faturamentoMOOrcamentoFormat,
            ...toRefs(data),
            animate,
            optionsConcessionaria,
            codConcessionariaSelected,
            Orcamentosrealizados,
            title,
            trataData
        };
    },
});

