
import { defineComponent, onMounted, reactive, toRefs, ref, watch } from "vue";
import moment from 'moment';
import { getPesquisasConcessionaria } from "@/services/OnePageReportService";
import { getPesquisasRealizadas } from "@/services/OnePageReportService";
import { getPesquisasAbaixoMedia } from "@/services/OnePageReportService";
import { getPesquisasAcimaMedia } from "@/services/OnePageReportService";
import { ContentLoader } from 'vue-content-loader';
import ApexChart from "apexcharts";
import store from "@/store/index";


export default defineComponent({
    name: "PesquisaSatisfacaoCard",
    components: {
        ContentLoader 
    },
    props: {
        filters: Object,
        title: String,
    },
    setup(props) {
        const title = ref("");

        const data = reactive({
            pesquisaSatisfacao: {
                pesquisasRealizadas: 0,
                pesquisasRealizadasHoje: 0,
                pesquisasAbaixoMedia: 0,
                pesquisasAcimaMedia: 0,
            },
        });


        let pesquisasRealizadas = ref(0);
        let pesquisasRealizadasHoje = ref(0);
        let pesquisasAbaixoMedia = ref(0);
        let pesquisasAcimaMedia = ref(0);
        let pesquisasRealizadasFormat = ref("");
        let pesquisasRealizadasHojeFormat = ref("");
        let pesquisasAbaixoMediaFormat = ref("");
        let pesquisasAcimaMediaFormat = ref("");
        
	    let animate = ref(true);
        
        onMounted(() =>{
        
        })

        function trataData(data){
            let filters = data;
            data = data.split("/");
            const dataInicial = moment(data[0]);
            const dataFinal = moment(data[1]);
            const mesmoAno = dataFinal.year() === dataInicial.year();
            const mesmoMes = dataFinal.month() === dataInicial.month();
            if (mesmoAno && mesmoMes) {
                title.value = `Período selecionado ${dataInicial.locale("pt-br").format('MMMM - YYYY').replaceAll("-", "de")}`;
                return filters;
            }else{
                title.value = ` Entre ${dataInicial.locale("pt-br").format('MMMM - YYYY').replaceAll("-", "de")} até ${dataFinal.locale("pt-br").format('MMMM - YYYY').replaceAll("-", "de")}`;
                return filters;
            }
        }

        function retornaFiltroData(dataInicial, dataFinal){
            dataInicial = moment(dataInicial).format();
            dataFinal = moment(dataFinal).format();
            dataInicial = dataInicial.substr(0, 10) + " 00:00:00";
            dataFinal = dataFinal.substr(0, 10) + " 23:59:59";

            const filters = `${dataInicial}/${dataFinal}`;

            return filters;
        };

        function formatFiltro(data){
            let dataSeparada = data.split("/");
            let dataSeparadaInicio = dataSeparada[0].split(" ");
            let dataSeparadaFim = dataSeparada[1].split(" ");
            dataSeparadaInicio = dataSeparadaInicio[0].split("-");
            dataSeparadaFim = dataSeparadaFim[0].split("-");
            dataSeparada = dataSeparadaInicio[2]+"/"+dataSeparadaInicio[1]+"/"+dataSeparadaInicio[0]+" - "+dataSeparadaFim[2]+"/"+dataSeparadaFim[1]+"/"+dataSeparadaFim[0];

            return {"data": dataSeparada,"pesquisa":""};
        }

        let link_v2 = ref("");

        watch(() => props.filters, () => {
            // 2021-04-01/2021-05-31 É um exemplo com dados
             const filters = trataData(props.filters?.data); 

            let dominio_gestao = store.getters.layoutConfig("distribuidor.dominio_gestao");
            if(dominio_gestao == "localhost:8080"){
                link_v2.value = "http://localhost/acampa-gestao-pra/v2/";
            }else{
                link_v2.value = "../v2/";
            }

            getPesquisasList(props.filters?.codConcessionaria, props.filters?.data);
        });

        let pesquisaCadastrada = ref(false);

        async function getPesquisasList(codConcessionaria, filtersData){
            
		    animate.value = true;
            
            let diaHojeInicial = moment().startOf("day").toISOString();
            let diaHojeFinal = moment().endOf("day").toISOString();
            let dataTratada = retornaFiltroData(diaHojeInicial, diaHojeFinal);
            let filtroFormatado = formatFiltro(filtersData);
            let filtroFormatadoHoje = formatFiltro(dataTratada);
            let pesquisaInfo;
            let pesquisaHojeInfo;
            let pesquisasAbaixoMediaInfo;
            let pesquisasAcimaMediaInfo;            
            let pesquisasConcessionaria = await getPesquisasConcessionaria(codConcessionaria);
            
            pesquisasRealizadas.value = 0;
            pesquisasRealizadasHoje.value = 0;
            pesquisasAcimaMedia.value = 0;
            pesquisasAbaixoMedia.value = 0;

            pesquisaCadastrada.value = pesquisasConcessionaria.pesquisa[0] != '';
            if(pesquisaCadastrada.value){                
                for(const pesquisa of pesquisasConcessionaria.pesquisa) {
                    pesquisaInfo =  await getPesquisasRealizadas(codConcessionaria, pesquisa, filtroFormatado);
                    pesquisaHojeInfo = await getPesquisasRealizadas(codConcessionaria, pesquisa, filtroFormatadoHoje);
                    pesquisasAbaixoMediaInfo = await getPesquisasAbaixoMedia(codConcessionaria, pesquisa, filtroFormatado);
                    pesquisasAcimaMediaInfo = await getPesquisasAcimaMedia(codConcessionaria, pesquisa, filtroFormatado);
                    if(pesquisaInfo.dados.length>0){
                        pesquisasRealizadas.value += pesquisaInfo.dados[0].quantidade ? parseInt(pesquisaInfo.dados[0].quantidade) :0; 
                    }
                    if(pesquisaHojeInfo.dados.length>0){
                        pesquisasRealizadasHoje.value += pesquisaHojeInfo.dados[0].quantidade ? parseInt(pesquisaHojeInfo.dados[0].quantidade):0; 
                    }
                    if(pesquisasAbaixoMediaInfo.dados.length>0){
                        pesquisasAbaixoMedia.value += pesquisasAbaixoMediaInfo.dados[0].quantidade?parseInt(pesquisasAbaixoMediaInfo.dados[0].quantidade):0; 
                    }
                    if(pesquisasAcimaMediaInfo.dados.length>0){
                        pesquisasAcimaMedia.value += pesquisasAcimaMediaInfo.dados[0].quantidade? parseInt(pesquisasAcimaMediaInfo.dados[0].quantidade):0; 
                    }                    
                }
            }
            
            pesquisasRealizadasFormat.value = new Intl.NumberFormat('de-DE',{ maximumFractionDigits: 0, minimumFractionDigits:0 }).format(pesquisasRealizadas.value);
            pesquisasRealizadasHojeFormat.value = new Intl.NumberFormat('de-DE',{ maximumFractionDigits: 0, minimumFractionDigits:0 }).format(pesquisasRealizadasHoje.value);
            pesquisasAbaixoMediaFormat.value = new Intl.NumberFormat('de-DE',{ maximumFractionDigits: 0, minimumFractionDigits:0 }).format(pesquisasAbaixoMedia.value);
            pesquisasAcimaMediaFormat.value = new Intl.NumberFormat('de-DE',{ maximumFractionDigits: 0, minimumFractionDigits:0 }).format(pesquisasAcimaMedia.value);
    
            animate.value = false;
        }

        return {
            link_v2,
            title,
            trataData,
            pesquisasRealizadasFormat,
            pesquisasRealizadasHojeFormat,
            pesquisasAbaixoMediaFormat,
            pesquisasAcimaMediaFormat,
            ...toRefs(data),
            animate,
            pesquisaCadastrada
        };
    },
});

