
import ApiService from "../services/ApiService"
import FaturamentoTotalCard from "../components/cards/FaturamentoTotalCard.vue";
import IndicadoresVendasCard from "../components/cards/IndicadoresVendasCard.vue";
import ConversaoPacotesCard from "../components/cards/ConversaoPacotesCard.vue";
import ConsultoresCard from "../components/cards/ConsultoresCard.vue";
import ProdutosMaisVendidosCard from "../components/cards/ProdutosMaisVendidosCard.vue";
import FaturamentoUltimos6MesesCard  from "../components/cards/FaturamentoUltimos6MesesCard.vue";
import ChecklistsCard  from "../components/cards/ChecklistsCard.vue";
import PesquisaSatisfacaoCard  from "../components/cards/PesquisaSatisfacaoCard.vue";
import UsoFerramentaCard  from "../components/cards/UsoFerramentaCard.vue";
import OficinaDigitalCard  from "../components/cards/OficinaDigitalCard.vue";
import CreateAppModal from "../components/modals/wizards/CreateAppModal.vue";

import { useStore } from "vuex";
import { computed, defineComponent, reactive, ref,onMounted} from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { UsuarioModel } from "@/models/UsuarioModel";
import { useRouter } from "vue-router";
import useEmitter from '@/composables/Emmiter'
import moment from "moment";
import { getFaturamentosTotais, getMetasConcessionarias } from "@/services/OnePageReportService";

import AuthService from "@/services/AuthService";

export default defineComponent({
  name: "dashboard",
  components: {
	FaturamentoTotalCard,
	IndicadoresVendasCard,
	ConversaoPacotesCard,
	ConsultoresCard,
	ProdutosMaisVendidosCard,
	FaturamentoUltimos6MesesCard,
	ChecklistsCard,
	PesquisaSatisfacaoCard,
	UsoFerramentaCard,
	OficinaDigitalCard,
	CreateAppModal
  },
  props: {
	  filters: Object,
	  filtersInicial: Object
	  
  },

  setup(props) {
    const exibeModal = process.env.VUE_APP_EXIBE_MODAL && process.env.VUE_APP_EXIBE_MODAL === "false" ? false : true;
 	const emitter = useEmitter();
    const store = useStore();

	const filters = ref([]);

	const loadingFaturamentoTotal = ref(true);
	const dataFaturamentoTotal:any = ref([]);
	const indicadoresVendas:any = ref([]);
	const percentualConversaoPacotes = ref("");
	const somaRevisoes = ref(0);

	const metasConcessionaria = ref();

	const userInfo = AuthService.getUsuarioAuth();
	const codNivel = userInfo.codNivel;

	// console.log('filtrar antes')
	emitter.on("filtrar", params => {
		// console.log("filtrar dashboard", params)
		filters.value = params;
		const filtersData = trataFilters(params.data);

		listaFaturamentosTotais(params.codConcessionaria, filtersData.data, filtersData.oneMounth, filtersData.title);
		listaMetasConcessionaria(params.codConcessionaria);
    });
	
	// Registra a função f para ser executada quando o documento terminar de carregar.
	// Se o documento já foi carregado, execute-o de maneira assíncrona o mais rápido possível.

	function onLoad(f) {
		if (onLoad.loaded) // Se o documento já estiver carregado
			window.setTimeout(f, 0); // A fila f deve ser executada o mais rápido possível
		else if (window.addEventListener) // Método padrão de registro de eventos
			window.addEventListener("load", f, false);	
	}
		// Comece definindo um sinalizador que indica que o documento ainda não foi carregado.
		onLoad.loaded = false;
		// E registre uma função para definir o sinalizador quando o documento for carregado

	onLoad(function() { onLoad.loaded = true; });

	function trataFilters(data) {
	  	let oneMounth = false;
		let title = ""; 

      	data = data.split("/");
      	const dataInicial = moment(data[0]);
      	const dataFinal = moment(data[1]);
      	const mesmoAno = dataFinal.year() === dataInicial.year();
      	const mesmoMes = dataFinal.month() === dataInicial.month();

/* 		const modalFiltro:any = ref();
        const showClose = ref(false);
        const manyConcessionarias = ref(false);

		function openModal(staticModal = false){
            const refModal = document.getElementById("modal-join");
            if(staticModal){
                modalFiltro.value = new Modal(refModal, {keyboard: false, backdrop: 'static'});
                showClose.value = false;
            } else {
                modalFiltro.value = new Modal(refModal);
                showClose.value = true;
            }
            modalFiltro.value.show();
        } 
		   */

      	if (!(mesmoAno && mesmoMes)) {
			return {
				data: data[0] + "/" + data[1],
				oneMounth,
				title: `entre ${dataInicial.locale("pt-br").format('MMMM - YYYY').replaceAll("-", "de")} e ${dataFinal.locale("pt-br").format('MMMM - YYYY').replaceAll("-", "de")}`
			}
      	};

		const diferencaMesFinalAteAtual = Math.round(dataFinal.diff(moment(), 'months', true));
		if (diferencaMesFinalAteAtual == 0) {
			title = "nos últimos 6 meses";
		} else {
			title = `nos 6 meses antecessores a ${dataFinal.locale("pt-br").format('MMMM - YYYY').replaceAll("-", "de")}`;
		};

		oneMounth = true;
      	const dataFinalFormat = dataFinal.format('YYYY-MM-DD');
      	const sixMonths = dataFinal.subtract(5, 'months').format('YYYY-MM-DD');
		const dataTratada = sixMonths + "/" + dataFinalFormat;
      	return {
			data: dataTratada,
			oneMounth,
			title
		};
    }

	emitter.on("exportarOnePageReport", data => {
		// console.log("Exportar PDF");
    });
	
	onMounted(async ()=>{     
		if(codNivel == 3){
			router.push({ name: "gerenciar-oficina-digital" });
			return
		}                 
	});

	async function listaFaturamentosTotais(codConcessionaria, filtersData, oneMounth, title){
		loadingFaturamentoTotal.value = true;
		const response = await getFaturamentosTotais(codConcessionaria, filtersData);
		var somaPacotes = 0;
		
		if(oneMounth){
			dataFaturamentoTotal.value = [response[response.length - 1]];
			indicadoresVendas.value = {data: response, oneMounth, title};
				somaPacotes = [response[response.length - 1]].filter(concessionaria => concessionaria.pacotes).map(concessionaria => concessionaria.pacotes).reduce((valorAcumulado, valorAtual) => { return valorAcumulado + valorAtual }, 0);
				somaRevisoes.value = [response[response.length - 1]].filter(concessionaria => concessionaria.revisoes).map(concessionaria => concessionaria.revisoes).reduce((valorAcumulado, valorAtual) => { return valorAcumulado + valorAtual }, 0);
		} else {
			dataFaturamentoTotal.value = response;
			indicadoresVendas.value = {data: response, oneMounth, title};
				 somaPacotes = response.filter(concessionaria => concessionaria.pacotes).map(concessionaria => concessionaria.pacotes).reduce((valorAcumulado, valorAtual) => { return valorAcumulado + valorAtual }, 0);
				somaRevisoes.value = response.filter(concessionaria => concessionaria.revisoes).map(concessionaria => concessionaria.revisoes).reduce((valorAcumulado, valorAtual) => { return valorAcumulado + valorAtual }, 0);
		}

		const calculoPercentualPacotes = somaRevisoes.value==0?0:(((somaPacotes / somaRevisoes.value) < 1 ? (somaPacotes / somaRevisoes.value) : 1) * 100);
		percentualConversaoPacotes.value = calculoPercentualPacotes && !isNaN(calculoPercentualPacotes) ? calculoPercentualPacotes.toFixed(2) : "0";

		loadingFaturamentoTotal.value = false;
	}
	
	async function listaMetasConcessionaria(codConcessionaria){
		const response = await getMetasConcessionarias(codConcessionaria);
		metasConcessionaria.value = response;
	};

	

	const router = useRouter()
	/// pega se o usuario esta logado e qual o usuario
	/// temos que fazer quando o usuario foi desconectado ex alteramos a senha do cara ele ser redirecionado para o login
	// const store = useStore();
	// console.log('store.getters.isUsuarioLogado',store.getters.isUsuarioLogado);
	// console.log('store.getters.usuarioLogado',store.getters.usuarioLogado);

	// console.log('entrou aqui setup');

	// ApiService.get('faturamentostotaisonepagereport/14/2021-04-01/2021-05-31').then(response => {
	// console.log('api response ', response)
	// })


    let geraPercentualGraficoFaturamentoTotal = function(faturamentoTotalRealizado, faturamentoTotalNaoRealizado ){
      let faturamentoTotal = faturamentoTotalRealizado+faturamentoTotalNaoRealizado;
      let percentualTotalRealizado;
      let percentualTotalNaoRealizado;
      percentualTotalRealizado = ((faturamentoTotalRealizado*100)/faturamentoTotal).toFixed(2);
      percentualTotalNaoRealizado = ((faturamentoTotalNaoRealizado*100)/faturamentoTotal).toFixed(2);
      return [percentualTotalRealizado,percentualTotalNaoRealizado];
	  }

    let faturamentoTotalRealizado = 58726.85;
		let faturamentoTotalNaoRealizado = 28237.24;
		let percentuaisFaturamento = geraPercentualGraficoFaturamentoTotal(faturamentoTotalRealizado, faturamentoTotalNaoRealizado);
		let faturamentoTotalRealizadoFormat = new Intl.NumberFormat('de-DE',{ maximumFractionDigits: 0, minimumFractionDigits:0 }).format(faturamentoTotalRealizado);

	return { 
		filters, 
		dataFaturamentoTotal, 
		indicadoresVendas, 
		loadingFaturamentoTotal, 
		percentualConversaoPacotes, 
		metasConcessionaria, 
		somaRevisoes, 
		temPacote: computed(() => store.getters.concessionariaSelectedInfo.temPacote ?? false),
		temOficinaDigital: computed(() => store.getters.concessionariaSelectedInfo.temOficinaDigital ?? false),
		temChecklist: computed(() => store.getters.concessionariaSelectedInfo.temChecklist ?? false),
    exibeModal
	}
  },
});
