<template>
   
	<div class="card card-xxl-stretch" >
		<!--begin::header-->
    <div class="card-header border-0 pt-5">
      <h6 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-5 mb-1 fw-bolder cor_texto_1">Produtos mais vendidos</span>
      </h6>
    </div>
		<!--end::Header-->	
		<ContentLoader :animate="true" viewBox=" 0 80 450 400" v-if="animate">
    		<rect x="549" y="497" rx="0" ry="0" width="74" height="61" /> 
    		<rect x="19" y="87" rx="0" ry="0" width="4" height="54" /> 
    		<rect x="31" y="91" rx="0" ry="0" width="1" height="0" /> 
    		<rect x="224" y="134" rx="0" ry="0" width="67" height="5" /> 
    		<rect x="325" y="133" rx="0" ry="0" width="68" height="6" /> 
    		<rect x="41" y="101" rx="0" ry="0" width="1" height="0" /> 
    		<rect x="29" y="135" rx="0" ry="0" width="67" height="5" /> 
    		<rect x="121" y="133" rx="0" ry="0" width="68" height="5" /> 
    		<rect x="20" y="151" rx="0" ry="0" width="4" height="54" /> 
    		<rect x="32" y="155" rx="0" ry="0" width="1" height="0" /> 
    		<rect x="225" y="198" rx="0" ry="0" width="67" height="5" /> 
    		<rect x="326" y="197" rx="0" ry="0" width="68" height="6" /> 
    		<rect x="42" y="165" rx="0" ry="0" width="1" height="0" /> 
    		<rect x="30" y="199" rx="0" ry="0" width="67" height="5" /> 
    		<rect x="122" y="197" rx="0" ry="0" width="68" height="5" /> 
    		<rect x="20" y="209" rx="0" ry="0" width="4" height="54" /> 
    		<rect x="32" y="213" rx="0" ry="0" width="1" height="0" /> 
    		<rect x="225" y="256" rx="0" ry="0" width="67" height="5" /> 
    		<rect x="326" y="255" rx="0" ry="0" width="68" height="6" /> 
    		<rect x="42" y="223" rx="0" ry="0" width="1" height="0" /> 
    		<rect x="30" y="257" rx="0" ry="0" width="67" height="5" /> 
    		<rect x="122" y="255" rx="0" ry="0" width="68" height="5" /> 
    		<rect x="21" y="273" rx="0" ry="0" width="4" height="54" /> 
    		<rect x="33" y="277" rx="0" ry="0" width="1" height="0" /> 
    		<rect x="226" y="320" rx="0" ry="0" width="67" height="5" /> 
    		<rect x="327" y="319" rx="0" ry="0" width="68" height="6" /> 
    		<rect x="43" y="287" rx="0" ry="0" width="1" height="0" /> 
    		<rect x="31" y="321" rx="0" ry="0" width="67" height="5" /> 
    		<rect x="123" y="319" rx="0" ry="0" width="68" height="5" /> 
    		<rect x="21" y="333" rx="0" ry="0" width="4" height="54" /> 
    		<rect x="226" y="380" rx="0" ry="0" width="67" height="5" /> 
    		<rect x="327" y="379" rx="0" ry="0" width="68" height="6" /> 
    		<rect x="31" y="381" rx="0" ry="0" width="67" height="5" /> 
    		<rect x="123" y="379" rx="0" ry="0" width="68" height="5" /> 
    		<rect x="21" y="391" rx="0" ry="0" width="4" height="54" /> 
    		<rect x="33" y="395" rx="0" ry="0" width="1" height="0" /> 
    		<rect x="226" y="438" rx="0" ry="0" width="67" height="5" /> 
    		<rect x="327" y="437" rx="0" ry="0" width="68" height="6" /> 
    		<rect x="43" y="405" rx="0" ry="0" width="1" height="0" /> 
    		<rect x="31" y="439" rx="0" ry="0" width="67" height="5" /> 
    		<rect x="123" y="437" rx="0" ry="0" width="68" height="5" /> 
    		<rect x="34" y="459" rx="0" ry="0" width="1" height="0" />    		
        </ContentLoader>
		<!-- <ContentLoader :animate="true" :width='500' :height='500' v-if="animate">
		<rect x="544" y="497" rx="0" ry="0" width="74" height="61" /> 
		<rect x="19" y="87" rx="0" ry="0" width="4" height="54" /> 
		<rect x="31" y="91" rx="0" ry="0" width="1" height="0" /> 
		<rect x="224" y="132" rx="0" ry="0" width="67" height="10" /> 
		<rect x="325" y="133" rx="0" ry="0" width="68" height="10" /> 
		<rect x="41" y="101" rx="0" ry="0" width="1" height="0" /> 
		<rect x="29" y="132" rx="0" ry="0" width="67" height="10" /> 
		<rect x="121" y="132" rx="0" ry="0" width="68" height="10" />
        </ContentLoader> -->

		<!--begin::Body-->
	<div class="card-body d-flex flex-column pt-1 cor_texto_2" style="position: relative;" v-if="!animate">
		<div class="scroll-x">
			<div v-if="items.length == 0">
				Nenhuma Informação foi econtrada nesse período
			</div>

			<div class="border-0 pt-2 min-w-400px" v-if="items.length > 0">
				<div class="d-flex">
					<div class="w-25">
					<h6 class="card-title align-items-start flex-column">
						<span class="card-label fw-bolder fs-6 mb-1 fw-bolder cor_texto_1">Produto</span>
					</h6>
					</div>

					<div class="w-25">
					<h6 class="card-title align-items-start flex-column">
						<span class="card-label fw-bolder fs-6 mb-1 fw-bolder cor_texto_1">Quantidade</span>
					</h6>
					</div>

					<div class="w-25">
					<h6 class="card-title align-items-start flex-column">
						<span class="card-label fw-bolder fs-6 mb-1 fw-bolder cor_texto_1">Penetração</span>
					</h6>
					</div>

					<div class="flex-grow-1">
					<h6 class="card-title align-items-start flex-column">
						<span class="card-label fw-bolder fs-6 mb-1 fw-bolder cor_texto_1">Faturamento</span>
					</h6>
					</div>
				</div>
			</div>

			<div class="scroll h-400px pe-2 mb-2 w-100 min-w-400px" style="margin-top: 35px;">
				<div class="d-flex align-items-center mb-8" v-for="(item, index) in items" :key="index">
				
				<!--begin::Title-->
				<div class="fw-bolder fs-8 mb-1 cor_texto_2 d-flex w-25 me-4">
					<div class="me-4 d-flex flex-column justify-content-center">
						<span class="bullet bullet-vertical h-40px background_layout_1"></span>
					</div>
					<div class="d-flex flex-column justify-content-center">
            <span>{{item.nome.toUpperCase()}}</span>
					</div>
				</div>

				<div class="fw-bolder fs-7 mb-1 cor_texto_2 w-25 ms-0">
					{{item.quantidade}} itens
				</div>
				<!--end::Title-->
				<!--begin::Info-->
				<div class="fw-bolder fs-7 mb-1 cor_texto_2 w-25 ps-1">
					{{item.penetracao.toLocaleString('pt-Br', {maximumFractionDigits: 2})}}%
				</div>

				<div class="fw-bolder fs-7 mb-1 flex-grow-1 cor_texto_2">
					R$ {{ item.valorTotal.toLocaleString('pt-Br', {minimumFractionDigits: 2})}}
				</div>
				<!--end::Info-->
				</div>
			</div>
      	</div>
			
			<div class="text-end py-lg-0 py-2" v-if="items.length > 0">
				<a href="#" class="btn btn-sm btn-outline background_btn_2_opct border_btn_1 cor_btn_1" data-bs-toggle="modal" data-bs-target="#modal_produtos_vendidos">Ver mais</a>
			</div>
			
		</div>	
		<ProdutosMaisVendidosModal :filtersInicial="filters" /> 
		<!--end::Body-->
	</div>
	

</template>

<script>
import { defineComponent, onMounted, ref, watch } from "vue";
import { getProdutosMaisVendidos } from "@/services/OnePageReportService.ts" //Função que consome api
import ProdutosMaisVendidosModal from '@/components/modals/OnePageReportModals/ProdutosMaisVendidosModal.vue'
import { ContentLoader } from 'vue-content-loader'

export default defineComponent({
	name: "ProdutosMaisVendidosCard",
	components: {
		ProdutosMaisVendidosModal,
		ContentLoader
	},
	props: {
    	filters: Object,
    	somaRevisoes: Number
	},

	setup(props) {
		const items = ref([]);
		let animate = ref(true);
		let revisoes = ref(0);

		function comparaValorMaior(valorA, valorB){		
			if (valorA.valorTotal > valorB.valorTotal) {
				return -1;
			}
			if (valorA.valorTotal < valorB.valorTotal) {
				return 1;
			}

			return 0;
		};

    watch(() => props.somaRevisoes, () => {
      revisoes.value = props.somaRevisoes && props.somaRevisoes > 0 ? props.somaRevisoes : 0;
      listaFaturamentosTotais(props.filters.codConcessionaria, props.filters.data);
    });

    watch(() => props.filters, () => {
      animate.value = false;
    });

		async function listaFaturamentosTotais(codConcessionaria, filtersData){
			animate.value = true;
			items.value = [];
			const response = await getProdutosMaisVendidos(codConcessionaria, filtersData)
			
			response.forEach((arrayMesArray, i) => {
				arrayMesArray[1].forEach((item) => {
					const faturamentoFormatado = item.faturamentoProdServicoVendidos.replaceAll(".","").replace('R$', "").replace(',', ".");

					// Retorna true se já existir um item no array com o mesmo código
					const itemRepetido = items.value.some(itemItems => {
						return itemItems.codServico == item.codServico;
					})

					// Se existe um item repetido, soma os valores de quantidade e valor. Senão cria um novo item
					if(itemRepetido) {
						// Busca o id do item repetido
						const idItem = items.value.findIndex((itemItems) => item.codServico === itemItems.codServico);
						// Soma quantidade e valor ao item
						items.value[idItem].quantidade += parseInt(item.qtdProdServicoVendidos);
						items.value[idItem].valorTotal += parseFloat(faturamentoFormatado);
            items.value[idItem].penetracao = revisoes.value ? ((items.value[idItem].quantidade / revisoes.value) * 100) : 0;
					} else {
						items.value.push({
							codServico: item.codServico,
							nome: item.nomeProdServico,
							quantidade: parseInt(item.qtdProdServicoVendidos),
							valorTotal: parseFloat(faturamentoFormatado),
							penetracao: revisoes.value ? ((item.qtdProdServicoVendidos / revisoes.value) * 100) : 0
						});
					}
				});
			});

			items.value.sort(comparaValorMaior);
			animate.value = false;
		};

		return { items, animate };
	}
});


</script>