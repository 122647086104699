

import { defineComponent, onMounted, reactive, toRefs, ref, watch } from "vue";
import { getFaturamentosTotais } from "@/services/OnePageReportService";
import ApexChart from "apexcharts";
import { ContentLoader } from 'vue-content-loader';
import moment from "moment";

export default defineComponent({
  name: "FaturamentoTotalCard",
  components: {
    ContentLoader
  },
  props: {
    filters: Object,

    title: String,

    dataFaturamentoTotal: Array,

    loading: Boolean,

    temPacote: Boolean,

    cardClasses: String,

    avatar: String,

    online: Boolean,

    initials: String,

    color: String,

    name: String,

    position: String,

    avgEarnings: String,

    totalSales: String,

  },
  setup(props) {
    let animate = ref(true);
    const title = ref("Período selecionado");
 
    const data = reactive({
      faturamento: {
        valorTotal: 0,
        valorPecas: 0,
        valorMO: 0,
        valorDistribuidor: 0,
        valorTotalNaoRealizado: 0,
        percentualDistribuidor: 0,
        percentualNaoRealizado :0,
        dia: 0,
        dataAtualizacao:0,
      },
    });

    function createChartOptions(valorPrincipal){
      const optionsChart = {
        chart: {
          type: 'radialBar',
          offsetY: -20,
          height: props.temPacote ? 230 : 260,
          sparkline: {
            enabled: true
          }
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            hollow: {
              margin: 0,
              size: '78%',
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: 'front'
          },
          track: {
            background: 'var(--cor_indicadores_1_opct)',
            strokeWidth: '67%',
            margin: 0        
          },
          dataLabels: {
            name: {
              show: true,
              fontSize: '10px',
              color: 'var(--cor_texto_2)'
            },
            
            value: {
              offsetY: -32,
              fontWeight:  'bold',
              fontSize: '19px',
              color: 'var(--cor_texto_1)',
              formatter: function (val) {
                return valorPrincipal;
                }
              }
            }
          }
        },
        grid: {
          padding: {
            top: -10
          }
        },
        stroke: {
          lineCap: 'round'
        },
        fill:{
          colors: ['var(--cor_indicadores_1)']
        },
        labels: ['Faturamento'],
      };
      return optionsChart;
    }

    const optionsChart = createChartOptions("R$ 0.000,00");

    let somaValorTotalNaoRealizadoFormat =  ref("");
    let somaValorDistribuidorFormat =  ref("");
    let somaPercentualConversaoPacotesNaoRealizadoFormat = ref("");
    let somaPercentualConversaoPacotesFormat = ref("");
    let somaValorTotalFormat = ref("");
    let somaValorPecaFormat = ref("");
    let somaValorMOFormat = ref("");
    let somaPercentualFaturamentoDistribuidorFormat = ref("");
    let somaPacotes = ref(0);
    let somaPercentualConversaoPacotesNaoRealizado = ref(0);
    let somaPercentualConversaoPacotes = ref(0);
    let somaPercentualFaturamentoDistribuidor = ref(0);
    let somaRevisoes = ref(0);
    let somaTicketMedioDistribuidor = ref(0);
    let somaTicketMedioGeral = ref(0);
    let somaProdutosDistribuidor = ref(0);
    let somaProdutosGeral = ref(0);
    let somaValorDistribuidor = ref(0);
    let somaValorTotal = ref(0);
    let somaValorPeca = ref(0);
    let somaValorMO = ref(0);
    let somaValorTotalNaoRealizado = ref(0);
    let percentuaisFaturamento = ref({});
    let chart = ref({});
    let series = ref({});
    let dataAtualizacao = ref("")
    let dia = ref("")


    let geraPercentualGraficoFaturamentoTotal = function(faturamentoTotalRealizado, faturamentoTotalNaoRealizado ){
      let faturamentoTotal = faturamentoTotalRealizado+faturamentoTotalNaoRealizado;
      let percentualTotalRealizado;
      let percentualTotalNaoRealizado;
      percentualTotalRealizado = ((faturamentoTotalRealizado*100)/faturamentoTotal).toFixed(2);
      percentualTotalNaoRealizado = ((faturamentoTotalNaoRealizado*100)/faturamentoTotal).toFixed(2);
      return [percentualTotalRealizado,percentualTotalNaoRealizado];
    };

    let chartInstance;

    onMounted(() =>{
      chartInstance = new ApexChart(document.querySelector("#chart"), {
        ...optionsChart,
        series: ["0.0"],
      });
      chartInstance.render();
    })

  
    watch(() => props.dataFaturamentoTotal, () => {
        // 2021-04-01/2021-05-31 É um exemplo com dados
        // console.log(props.dataFaturamentoTotal);
        listaFaturamentosTotais(props.dataFaturamentoTotal);
    });


    watch(() => props.filters, () => {
			if(props.filters){
				const filters = trataData(props.filters?.data);
			}
		});

    function trataData(data){
      let filters = data;
      data = data.split("/");
      const dataInicial = moment(data[0]);
      const dataFinal = moment(data[1]);
      const mesmoAno = dataFinal.year() === dataInicial.year();
      const mesmoMes = dataFinal.month() === dataInicial.month();
      if (mesmoAno && mesmoMes) {
        title.value = `Período selecionado ${dataInicial.locale("pt-br").format('MMMM - YYYY').replaceAll("-", "de")}`;
        return filters;
      }else{
        title.value = ` Entre ${dataInicial.locale("pt-br").format('MMMM - YYYY').replaceAll("-", "de")} até ${dataFinal.locale("pt-br").format('MMMM - YYYY').replaceAll("-", "de")}`;
        return filters;
      }
    }
   

    async function listaFaturamentosTotais(response){
      animate.value = true;
      somaPacotes.value = 0;
      somaRevisoes.value = 0;
      somaProdutosDistribuidor.value = 0;
      somaProdutosGeral.value = 0;
      somaValorDistribuidor.value = 0;
      somaValorTotal.value = 0;
      somaValorPeca.value = 0;
      somaValorMO.value = 0;
      somaValorTotalNaoRealizado.value = 0;
      somaValorTotalNaoRealizadoFormat.value = "0,00";
      somaPercentualConversaoPacotesNaoRealizadoFormat.value = "0,00";
      somaValorDistribuidorFormat.value = "0,00";
      somaValorPecaFormat.value = "0,00";
      somaValorMOFormat.value = "0,00";
      somaPercentualConversaoPacotesFormat.value = "0,00";


      if(!response || (!response[0] && !response[1])){
        chartInstance.updateOptions(createChartOptions("R$ 0.000,00"));
        chartInstance.updateSeries(["0.0"]);

        return;
      };

      

      response.forEach(function(item){
        if(!item)
          return;
        somaPacotes.value += item.pacotes;
        somaRevisoes.value += item.revisoes;
        somaProdutosDistribuidor.value += item.totalProdutosDistribuidor;
        somaProdutosGeral.value += item.totalProdutosGeral;
        somaValorDistribuidor.value += item.valorDistribuidor;
        somaValorTotal.value += item.valorTotal;
        somaValorMO.value += item.valorMaodeObra;
        somaValorPeca.value += item.valorPecas;
        somaValorTotalNaoRealizado.value += item.valorTotalNaoRealizado;
      });

      dia.value = response[response.length-1].dia;
      if(response[response.length-1].dataAtualizacao != 0){
        const dataAux = moment(response[response.length-1].dataAtualizacao);
        dataAtualizacao.value = dataAux.locale("pt-br").format('DD/MM/YYYY HH:mm');
      }else{
        dataAtualizacao.value = '0';
      }
      
      somaValorTotalNaoRealizado.value = isNaN(somaValorTotalNaoRealizado.value) || !somaValorTotalNaoRealizado.value ? 0 : somaValorTotalNaoRealizado.value;
  
      somaTicketMedioDistribuidor.value = somaProdutosDistribuidor.value/somaRevisoes.value;
      somaTicketMedioGeral.value = somaProdutosGeral.value/somaRevisoes.value;
      somaPercentualConversaoPacotes.value = ((somaPacotes.value/somaRevisoes.value)<1?(somaPacotes.value/somaRevisoes.value):1) * 100;
      somaPercentualConversaoPacotes.value = isNaN(somaPercentualConversaoPacotes.value) || !somaPercentualConversaoPacotes.value ? 0 : somaPercentualConversaoPacotes.value;



      somaPercentualConversaoPacotesNaoRealizado.value = 100 - somaPercentualConversaoPacotes.value;
      somaPercentualFaturamentoDistribuidor.value = (somaValorDistribuidor.value * 100) / somaValorTotal.value;
      somaPercentualFaturamentoDistribuidor.value = isNaN(somaPercentualFaturamentoDistribuidor.value) || !somaPercentualFaturamentoDistribuidor.value ? 0 : somaPercentualFaturamentoDistribuidor.value;
      somaPercentualConversaoPacotesNaoRealizadoFormat.value = new Intl.NumberFormat('de-DE',{ maximumFractionDigits: 2, minimumFractionDigits:2 }).format(somaPercentualConversaoPacotesNaoRealizado.value);
      somaPercentualConversaoPacotesFormat.value = new Intl.NumberFormat('de-DE',{ maximumFractionDigits: 2, minimumFractionDigits:2 }).format(somaPercentualConversaoPacotes.value);
      somaPercentualFaturamentoDistribuidorFormat.value = new Intl.NumberFormat('de-DE',{ maximumFractionDigits: 2, minimumFractionDigits:2 }).format(somaPercentualFaturamentoDistribuidor.value);
      somaValorTotalNaoRealizadoFormat.value = new Intl.NumberFormat('de-DE',{ maximumFractionDigits: 2, minimumFractionDigits:2 }).format(somaValorTotalNaoRealizado.value);
      somaValorDistribuidorFormat.value = new Intl.NumberFormat('de-DE',{ maximumFractionDigits: 2, minimumFractionDigits:2 }).format(somaValorDistribuidor.value);
      somaValorTotalFormat.value = new Intl.NumberFormat('de-DE',{ maximumFractionDigits: 2, minimumFractionDigits:2 }).format(somaValorTotal.value);
      somaValorPecaFormat.value = new Intl.NumberFormat('de-DE',{ maximumFractionDigits: 2, minimumFractionDigits:2 }).format(somaValorPeca.value);
      somaValorMOFormat.value = new Intl.NumberFormat('de-DE',{ maximumFractionDigits: 2, minimumFractionDigits:2 }).format(somaValorMO.value);


      percentuaisFaturamento.value = geraPercentualGraficoFaturamentoTotal(somaValorTotal.value, somaValorTotalNaoRealizado.value);
      series.value = isNaN(percentuaisFaturamento.value[0])?[0]:[percentuaisFaturamento.value[0]];

      chartInstance.updateOptions(createChartOptions(`R$ ${somaValorTotalFormat.value}`));
      chartInstance.updateSeries(series.value);

      animate.value = false;
    }

    return {
      animate,
      listaFaturamentosTotais,
      geraPercentualGraficoFaturamentoTotal,
      somaPercentualConversaoPacotesFormat,
      somaPercentualFaturamentoDistribuidorFormat,
      somaPercentualFaturamentoDistribuidor,
      somaPercentualConversaoPacotesNaoRealizadoFormat,
      somaValorTotalNaoRealizadoFormat,
      somaValorDistribuidorFormat,
      somaValorTotalFormat,
      somaValorPecaFormat,
      somaValorMOFormat,
      series,
      dataAtualizacao,
      chart,
      percentuaisFaturamento,
      ...toRefs(data),
      title,
      trataData,
      dia
    };
  },
});
